import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../Section/Section"

import "./Partners.scss"

export default function Partners() {
  const { t } = useTranslation()

  return (
    /* Adjusted investors logos */
    <div className="partners">
      <Section>
        <h2 className="mb-4" id="partners">
          {t("Navbar.partners")}
        </h2>
        <div className="logos-wrapper">
          <StaticImage
            imgClassName="yc"
            className="yc-wrapper"
            src="../../../images/YC.png"
            alt="Y Combinator logo"
            imgStyle={{ objectFit: "contain" }}
          ></StaticImage>
          <StaticImage
            className="efg-ev-wrapper"
            imgClassName="efg-ev"
            src="../../../images/efg-ev-logo.png"
            alt="EFG EV logo"
            imgStyle={{ objectFit: "contain" }}
          ></StaticImage>
          <StaticImage
            className="tcv-wrapper"
            imgClassName="tcv"
            src="../../../images/TCV.png"
            alt="Tanmiya Capital Ventures logo"
            imgStyle={{ objectFit: "contain" }}
          ></StaticImage>
          <StaticImage
            className="efg-wrapper"
            imgClassName="efg"
            src="../../../images/EFG.png"
            alt="EFG logo"
            imgStyle={{ objectFit: "contain" }}
          ></StaticImage>
        </div>
      </Section>
    </div>
  )
}
